<template>
  <div :id = "`comment-${comment.id}`" 
        class='comment-item flex w-full mb-4'>
    <img v-lazy="comment.profile.avatar_url" :alt="comment.profile.display_name"
         class='rounded-full mt-2'
         :class="comment.parent_id ? 'w-6 h-6' : 'w-8 h-8'">

    <div class='comment-content ml-4 flex-1 w-full'>
      <div :id="'post_comment_'+comment.id" class="grid grid-1fr-auto">
        <div class='w-full'>
          <div class="grid grid-auto-1fr">
            <div class="grid grid-1fr-auto-auto gap-x-2 mr-2 items-center">
              <a :href="comment.profile.public_url" class='truncate'>
                <span class='text-16px font-medium truncate'>
                  {{ comment.profile.display_name }}
                </span>
              </a>
              <span v-if='comment.profile.id == state.post_profile.id' class='rounded-full px-2 py-0 bg-primary text-12px'>
                {{ $t('comment_form.creator') }}
              </span>
              <span v-else-if="comment.profile.is_admin_user" class='rounded-full px-2 py-0 bg-primary text-12px'>
                {{ $t('comment_form.admin') }}
              </span>
              <span v-if='comment.parent_id' class='created_at text-12px text-grey-5'>
                {{ comment.created_at }}
              </span>
            </div>
          </div>
          <div v-if='!comment.parent_id' class='created_at text-12px text-grey-5'>
            {{ comment.created_at }}
          </div>
          <div class='content text-14px my-2'>
            {{ commentContent.state == 'original' ? commentContent.original : commentContent.translated }}
          </div>
          <button v-if="commentContent.state == 'original'" class="mb-4 text-grey-5 text-14px underline" @click="handleTranslate">
            {{ $t('comment_form.translate_btn') }} 
          </button>
          <button v-else class="mb-4 text-grey-5 text-14px underline" @click="handleTranslate">
            {{ $t('comment_form.original_btn') }} 
          </button>
        </div>

        <div v-if='state.curr_profile' class='comment-more relative'>
          <input :id="'comment-' + comment.id + '-more'" type='radio' name='comment-more' class='hidden'>
          <label :for="'comment-' + comment.id + '-more'" class='cursor-pointer'>
            <span class='icon more-icon'></span>
          </label>
          <div class='more-content absolute right-0'>
            <button v-if='state.curr_profile && state.curr_profile.id == comment.profile.id' @click.prevent='deleteComment(comment)' class='flex items-center' data-confirm='Are you sure?'>
              <span class='icon delete-icon mr-2'></span>
              <span class='min-w-[32px]'>{{ $t('comment_form.delete') }}</span>
            </button>
            <button v-else-if='state.curr_profile' class='flex items-center' @click.prevent='reportComment(comment)'>
              <span class='icon report-icon mr-2'></span>
              <span class='min-w-[32px]'>{{ $t('comment_form.report') }}</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if='comment.images.length > 0' class='flex mb-2 flex-wrap gap-2'>
        <template v-for="image in comment.images" :key="image.id">
          <div v-lazy:background-image="image.x1_url"
               class='inline-block image rounded-8px cursor-pointer'
               @click.prevent='openImageSlide(image)'>
          </div>
        </template>
      </div>

      <div class='post_likes flex items-center'>
        <span :id="'comment-like-' + comment.id + '-btn'"
              ref="likeBtn"
              class='icon like w-5 h-5'
              :class="comment.liked ? 'selected' : ''"
              @click='likeBtnClick(comment)'>
        </span>
        <span ref="likeCount" class='text-12px text-grey-5 ml-2 mr-4'>
          {{ comment.like_count || '' }}
        </span>
          <span ref="unlikeBtn"
                class='icon unlike w-5 h-5'
                :class="comment.unliked ? 'selected' : ''"
                @click='unlikeBtnClick(comment)'>
          </span>
        <span v-if='state.curr_profile && !comment.parent_id'
              class='ml-4 text-12px cursor-pointer cancel-btn'
              @click='replyBtnClick(comment)'>
          {{ $t('comment_form.reply') }}
        </span>
      </div>

      <div v-if='!comment.parent_id && comment.replies.length > 0' class='comment_replies my-2'>
        <input type='checkbox' 
               :id="'post_comment_' + comment.id + '_replies'"
               class='hidden'
               :checked="collapseReplies(comment)">

        <label :for="'post_comment_' + comment.id + '_replies'" class='flex items-center cursor-pointer'>
          <span class='icon arrow-icon w-6 h-6 mr-2'></span>
          <span class='text-primary text-14px'>
            <span class='text-primary font-bold'>
              {{comment.replies.length}}
            </span>
            {{ $t('comment_form.replies') }}
          </span>
        </label>

        <div class='comment_replies_content -mb-4'>
          <post-comment v-for="reply in comment.replies" :key="reply.id" :comment="reply" ></post-comment>
        </div>
      </div>

      <div v-if='state.curr_profile && reply_comment_id == comment.id'
           ref="commentReplyForm"
           id='comment-reply-form' 
           class='mt-4'>
        <div class='flex mt-2'>
          <img v-lazy="state.curr_profile.avatar_url" :alt="state.curr_profile.display_name" class='rounded-full w-6 h-6'>
          <textarea class='ml-4 w-full h-12 p-2 text-xs text-gray-10 focus:outline-none w-full default-input-klass'
            :value='replyContent' @keyup='updateReplyContent' />
        </div>
        <div class='flex justify-end mt-2'>
          <button class="px-2 btn-sm cancel-btn btn mr-2"
                  @click.prevent="cancelReplyComment(comment)">
            {{ $t('comment_form.cancel') }}
          </button>
          <button v-if='reply_uploading' 
                  id='reply-comment'
                  class="px-2 btn-sm rounded-full btn btn-secondary"
                  :disabled="true">
            {{ $t('comment_form.uploading') }}
          </button>
          <button v-else
                  id='reply-comment'
                  class="px-2 btn-sm rounded-full btn btn-secondary"
                  @click.prevent="submitReplyComment"
                  :disabled="!replyContent">
            {{ $t('comment_form.reply') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { state, slideState, reportState, commentState } from "../stores/postState";
import i18next from "i18next";

export default defineComponent({
  props: ['comment', 'reply_comment_id'],
  data() {
    return {
      reply_uploading: false,
      replyContent: '',
      // 用於顯示翻譯後的內容
      commentContent: {
        original: '',
        translated: '',
        state: 'original'
      },

      state,
      slideState,
      commentState,
      reportState,
    };
  },
  mounted() {
    this.commentContent = {
      original: this.comment.content,
      translated: '',
      state: 'original'
    }
  },
  updated() {
    const replyForm = this.$refs.commentReplyForm
    if (this.commentState.reply_comment_id && replyForm) {
      replyForm.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  },
  methods: {
    collapseReplies(comment) {
      // 網址上沒指定 comment_id 就略過
      if (this.commentState.target_comment_id != null) {
        // 找到要展開的 comment_id
        // 指定的 comment_id 有可能是 replied_comment_id，所以要巢狀找出父層是誰
        for (const comment of this.commentState.comment_items) {
          if (comment.id == this.commentState.target_comment_id) {
            this.commentState.reply_comment_id = comment.id

            break;
          } else {
            for (const reply of comment.replies) {
              if (reply.id == this.commentState.target_comment_id) {
                this.commentState.reply_comment_id = comment.id

                break;
              }
            }
          }
        }
        // 找到跳轉的 comment
        const targetComment = document.getElementById('post_comment_' + this.commentState.target_comment_id)

        if (targetComment) {
          targetComment.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }

      // 這邊會判斷是否需要展開 replies
      // 1. replies 數 < 3
      // 2. 回覆 comment
      return comment.replies.length < 3 || this.commentState.reply_comment_id == comment.id
    },
    likeBtnClick(comment) {
      this.likeComment(comment)
    },
    unlikeBtnClick(comment) {
      this.unlikeComment(comment)
    },
    openImageSlide(image) {
      this.slideState.fetchPath = '/comment_images'
      this.slideState.openSlide = true
      this.slideState.images = []
      this.slideState.startImageId = image.id
    },
    replyBtnClick(comment) {
      if (this.commentState.reply_comment_id != comment.id) {
        this.replyContent = ''
        this.commentState.reply_comment_id = comment.id
      } else {
        this.commentState.reply_comment_id = ''
      }
    },
    updateReplyContent(e) {
      this.replyContent = e.target.value
    },
    cancelReplyComment(comment) {
      document.getElementById('post_comment_' + comment.id).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      this.replyContent = ''
      this.commentState.reply_comment_id = ''
    },
    deleteComment(comment) {
      this.deleteComment(comment);
    },
    reportComment(comment) {
      document.getElementById('comment-' + comment.id + '-more').checked = false

      this.reportState.openModal = true
      this.reportState.targetId = comment.id
      this.reportState.targetType = 'comment'
      this.reportState.reportKey = null
      this.reportState.reportReason = null
    },
    submitReplyComment(e) {
      this.reply_uploading = true
      const requestUrl = `/posts/${this.state.post_id}/comments`;
      const formData = new FormData()

      formData.append('comment[parent_id]', this.commentState.reply_comment_id)
      formData.append('comment[content]', this.replyContent)

      fetch(requestUrl, {
        method: 'post',
        headers: {
          "X-CSRF-Token": this.state.CSRFtoken
        },
        body: formData,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then(errData =>{
              throw new Error(errData.message || 'Error in submission');
            })
          }
        })
        .then((data) => {
          console.log('post comment!!!', data)
          this.reply_uploading = false
          this.replyContent = '';

          this.fetchCommentItemData();
        })
    },
    async likeComment(comment) {

      if (this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }
      console.log(this.$refs.likeBtn)
      try {
        let likeCount
        const likeIcon = this.$refs.likeBtn

        if (likeIcon.classList.contains('selected')) {
          const requestUrl = '/api/v1/comments/' + comment.id + '/likes'
          const response = await fetch(requestUrl, {method: 'delete'});

          if (response.ok) {
            const data = await response.json();
            likeIcon.classList.remove('selected')
            likeCount = data['like_count']

            if (likeCount == 0) {
              likeCount = ''
            }
          }
        } else {
          const requestUrl = '/api/v1/comments/' + comment.id + '/likes'
          const response = await fetch(requestUrl, {method: 'post'});

          if (response.ok) {
            const data = await response.json();
            likeCount = data['like_count']

            const unlikeIcon = this.$refs.unlikeBtn
            unlikeIcon.classList.remove('selected')
            likeIcon.classList.add('selected')
          }
        }

        likeIcon.nextSibling.innerHTML = likeCount
      } catch (error) {
        console.log(error)
      }
    },
    async unlikeComment(comment) {
      if (this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }

      try {
        const unlikeIcon = this.$refs.unlikeBtn

        if (unlikeIcon.classList.contains('selected')) {
          const requestUrl = '/api/v1/comments/' + comment.id + '/unlikes'
          const response = await fetch(requestUrl, {method: 'delete'});

          if (response.ok) {
            const data = await response.json();
            unlikeIcon.classList.remove('selected')
          }
        } else {
          const requestUrl = '/api/v1/comments/' + comment.id + '/unlikes'
          const response = await fetch(requestUrl, {method: 'post'});

          if (response.ok) {
            const likeIcon = this.$refs.likeBtn
            const likeCountRef = this.$refs.likeCount

            if (likeIcon.classList.contains('selected')) {
              likeIcon.classList.remove('selected')

              let likeCount = parseInt(likeCountRef.innerHTML)
              likeCount -= 1

              if (likeCount == 0) {
                likeCount = ''
              }

              likeCountRef.innerHTML = likeCount
            }

            unlikeIcon.classList.add('selected')
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async deleteComment(comment) {
      if (this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }

      if (this.state.curr_profile.id != comment.profile.id){
        return
      }

      try {
        const api_path = '/api/v1/comments/' + comment.id
        const response = await fetch(api_path, {method: 'delete'});

        if (response.ok) {
          this.fetchCommentItemData()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchCommentItemData() {
      try {
        const api_path = '/api/v1/posts/' + this.state.post_id + '/comments'
        const response = await fetch(api_path);
        if (response.ok) {
          const data = await response.json();
          const itemsData = data['data']

          this.state.post_profile = data['post_profile']
          this.state.curr_profile = data['current_profile']

          if (itemsData.length == 0) {
            this.commentState.comment_items_not_found = true
          } else {
            this.commentState.comment_items = itemsData;
          }
        }
      } catch (error) {
        this.commentState.comment_items = [];
      }
    },
    async handleTranslate() {
      // content state
      if (this.commentContent.state == 'original') {
        this.commentContent.state = 'translated'
      } else {
        this.commentContent.state = 'original'
      }

      // 有翻譯過就不再翻譯
      if (this.commentContent.translated != '') return;
      
      const browserLocale = navigator.language;
      try {
        const api_path = '/api/v1/translates'
        const response = await fetch(api_path, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            target_locale: browserLocale,
            content: this.commentContent.original
          })
        });
        
        if (response.ok) {
          const data = await response.json();
          this.commentContent.translated = data['translate']
        }
      } catch (error) {
        console.error('translate', error)
      }
    }
  }
});
</script>
