<template>
  <div v-if='state.curr_profile' id='comment-form-profile' class="flex items-center mb-4">
    <img v-lazy="state.curr_profile.avatar_url" :alt="state.curr_profile.display_name" class='rounded-full w-8 h-8'>
    <span class="ml-4 text-16px font-medium">
      {{ state.curr_profile.display_name }}
    </span>
  </div>
  <form method="put">
    <div class="flex flex-col w-full relative">
      <textarea class='w-full h-24 p-2 text-xs text-gray-10 focus:outline-none w-full default-input-klass'
        name="comment_content" v-model='commentContent'
        :placeholder="$t('comment_form.comment_placeholder')"
        maxlength="1000"/>
      <div class="content-count">{{commentContentCount}}</div>
    </div>

    <div class='flex -mx-1 my-1'>
      <div v-for='(file, index) in commentImageFiles' class='relative'>
        <div @click.prevent="deleteImg(index)" class='delete-icon absolute w-5 h-5 right-0 top-0 cursor-pointer'></div>
        <div v-lazy:background-image="parseImgUrl(file)" class='image rounded-8px w-10 h-10 bg-cover m-1'></div>
      </div>
    </div>

    <div class="flex justify-between">
      <label class="btn btn-sm btn-secondary rounded-full pl-1 pr-2">
        <div class="flex items-center">
          <span class='icon w-8 h-8 -my-2'></span>
          <span>{{ $t('comment_form.add_img_btn') }}</span>
        </div>
        <input id="comment_images"
               class="hidden"
               multiple="multiple"
               name="comment[images_attributes][][file]"
               accept="image/jpeg,image/png,image/jpg"
               type="file"
               v-on:change="commentImgUpload">
      </label>
      <span>
        <button v-if='commentContent' 
                class="px-2 btn-sm cancel-btn btn mr-2"
                @click.prevent="cancelNewComment">
          {{ $t('comment_form.cancel') }}
        </button>
        <button v-if='uploading'
                id='submit-comment'
                class="px-2 btn-sm rounded-full btn btn-secondary"
                :disabled="true">
          {{ $t('comment_form.uploading') }}
        </button>
        <button v-else 
                id='submit-comment'
                class="px-2 btn-sm rounded-full btn btn-secondary"
                @click.prevent="submitNewComment"
                :disabled="!commentContent">
          {{ $t('comment_form.submit') }}
        </button>
      </span>
    </div>
  </form>
</template>

<script>
import { defineComponent } from "vue";
import { state, commentState } from "../stores/postState";

export default defineComponent({
  data() {
    return {
      uploading: false,
      commentContent: '',
      commentImageFiles: [],

      state,
      commentState,
    };
  },
  updated() {
    if (this.state.curr_profile == null){
      window.location.href = '/users/sign_in'
    }
  },
  computed: {
    commentContentCount() {
      return `${this.commentContent.length} / 1000`;
    }
  },
  methods: {
    commentImgUpload(e) {
      console.log('uplaod file', e.target.files)
      e.target.files.forEach((file) => {
        this.commentImageFiles.push(file)
      })
    },
    parseImgUrl(file){
      return URL.createObjectURL(file)
    },
    deleteImg(index) {
      this.commentImageFiles.splice(index, 1)
    },
    cancelNewComment() {
      this.commentContent = '';
      this.commentImageFiles = [];
    },
    submitNewComment(e) {
      this.uploading = true
      const requestUrl = `/posts/${this.state.post_id}/comments`;
      const formData = new FormData()

      formData.append('comment[content]', this.commentContent)
      this.commentImageFiles.forEach(function(file) {
        formData.append('comment[images_attributes][][file]', file)
      })

      fetch(requestUrl, {
        method: 'post',
        headers: {
          "X-CSRF-Token": this.state.CSRFtoken
        },
        body: formData,
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then(errData =>{
            throw new Error(errData.message || 'Error in submission');
          })
        }
      })
      .then((data) => {
        this.uploading = false
        this.commentContent = '';
        this.commentImageFiles = [];

        this.fetchCommentItemData();
      })
    },
    async fetchCommentItemData() {
      try {
        const api_path = '/api/v1/posts/' + this.state.post_id + '/comments'
        const response = await fetch(api_path);
        if (response.ok) {
          const data = await response.json();
          const itemsData = data['data']

          this.state.post_profile = data['post_profile']
          this.state.curr_profile = data['current_profile']

          if (itemsData.length == 0) {
            this.commentState.comment_items_not_found = true
          } else {
            this.commentState.comment_items = itemsData;
          }
        }
      } catch (error) {
        this.commentState.comment_items = [];
      }
    }
  }
});
</script>
