import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import PostCommentsCollection from './layouts/PostCommentsCollection.vue';
import CommentForm from './components/CommentForm.vue';
import ReviewForm from './components/ReviewForm.vue';
import PostReview from './components/PostReview.vue';
import PostImageFullScreenSlide from './components/PostImageFullScreenSlide.vue';
import ReportModel from './components/ReportModel.vue';
import PostComment from './components/PostComment.vue';
import PostReviewComment from './components/PostReviewComment.vue';

class PostCommentsComponent {
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }

  // Initialize the component
  init() {
    this.app = createApp(PostCommentsCollection);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });
    
    this.app.component('comment-form', CommentForm);
    this.app.component('review-form', ReviewForm);
    this.app.component('post-review', PostReview);
    this.app.component('post-image-full-screen-slide', PostImageFullScreenSlide);
    this.app.component('report-model', ReportModel);
    this.app.component('post-comment', PostComment);
    this.app.component('post-review-comment', PostReviewComment);

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default PostCommentsComponent;
