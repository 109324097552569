import { Fragment, render } from 'vue';
import algoliasearch from 'algoliasearch/lite';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import { createElement } from './utils/createElement';

import i18next from 'i18next';
const searchClient = algoliasearch(
  window.global_values.ALGOLIA__APP_ID,
  window.global_values.ALGOLIA__API_SEARCH_KEY,
);

function isParentElementHidden(element) {
  var parent = element.parentElement;
  if (parent) {
    const style = window.getComputedStyle(parent);
    return style.display === 'none';
  }
  return false;
}

const SvgIcon = () => (
  <svg width="16" height="16" class="mr-1" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.3697 8.02915C12.1475 8.02915 12.1475 5.59437 12.1475 5.59437C12.1475 5.59437 13.3327 6.81176 13.3327 9.85523C13.3327 12.8987 11.5549 15.3335 8.00086 15.3335C4.44681 15.3335 2.66602 12.8977 2.66602 9.85523C2.66602 5.59437 6.81416 6.20306 6.81416 1.3335C6.81416 1.3335 10.3697 3.15958 10.3697 8.02915Z" stroke="#FFA694" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.55794 14.7955C4.81664 10.4986 7.70738 8 7.70738 8C7.70738 8 7.70738 13.5462 10.6667 11.6975C10.6667 11.6975 10.6667 14.7171 8.8911 15.3333" stroke="#FFA694" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

class AutocompleteBar {
  constructor({ container }) {
    this.searchClient = searchClient;
    this.container = container;
    this.autocomplete = null;
    this.popularTags = []
    this.printerTags = []
  }

  init() {
    const popular_tag_json_string = document.querySelector(`${this.container} .popular-tags`).dataset.tags
    this.popularTags = JSON.parse(popular_tag_json_string) || []
    
    const printer_tag_json_string = document.querySelector(`${this.container} .printer-tags`).dataset.tags
    this.printerTags = JSON.parse(printer_tag_json_string) || []

    const designer_tag_json_string = document.querySelector(`${this.container} .designer-tags`).dataset.tags
    this.designerTags = JSON.parse(designer_tag_json_string) || []

    const containerElement = document.querySelector(this.container)
    if(!this.autocomplete && !isParentElementHidden(containerElement)) {
      this.createInstance()
    }
  }

  createInstance() {
    const createFooterTemplate = () => {
      return (
        <div className="aa-tags">
          <div className="aa-tags-container">
            <div className="aa-tags-title">{i18next.t('auto_complete_bar.popular_tags')}</div>
            <div className="aa-tags-items">
              {this.popularTags.map((tag, index) => (
                <a href={`/posts?kw=${tag.key}`} className="aa-tags-item">
                  {index < 5 && <SvgIcon /> }
                  {tag.value}
                </a>
              ))}
            </div>
          </div>
          <div className="aa-tags-container">
            <div className="aa-tags-title">{i18next.t('auto_complete_bar.popular_designers')}</div>
            <div className="aa-tags-items">
              {this.designerTags.map((tag) => (
                <a href={`/posts?kw=${tag.key}`} className="aa-tags-item">
                  <SvgIcon />
                  {tag.value}
                </a>
              ))}
            </div>
          </div>
          <div className="aa-tags-container">
            <div className="aa-tags-title">{i18next.t('auto_complete_bar.printer_type')}</div>
            <div className="aa-tags-items">
              {this.printerTags.map((tag) => (
                <a href={`/posts?kw=${tag.key}`} className="aa-tags-item">
                  {tag.value}
                </a>
              ))}
            </div>
          </div>
        </div>
      );
    };
    this.autocomplete = autocomplete({
      container: `${this.container} .autocomplete`,
      panelContainer: `${this.container} .search-panel`,
      openOnFocus: true,
      placeholder: i18next.t('auto_complete_bar.search'),
      initialState: {
        // 不使用空字串的話panel 不會打開
        query: new URL(window.location).searchParams.get('kw') || '',
      },
      getSources: ({ query }) => {
        if (query === '') {
          return [
            {
              sourceId: 'footerOnly',
              getItems: () => [],
              templates: {
                footer: createFooterTemplate,
                noResults() {
                  return '';
                },
              },
            },
          ];
        }
        return [
          {
            sourceId: 'profiles',
            getItems: () => {
              const results = getAlgoliaResults({
                searchClient: this.searchClient,
                queries: [
                  {
                    indexName: 'Profile',
                    query,
                    params: {
                      hitsPerPage: 2,
                    },
                  },
                ],
              });
              return results
            },
            onSelect: (event) => {
              event.setQuery(event.item.display_name);
              window.location.href = `/posts?kw=${event.item.display_name}`
            },
            templates: {
              item: ({ item, components }) => {
                return (
                  <div className="aa-ItemWrapper">
                    <div className="aa-ItemContent">
                      <div className="aa-ItemContentBody">
                        <div className="aa-ItemContentTitle">
                          <div class="item-name"><components.Snippet hit={item} attribute="display_name"/></div>
                          <div class="creator-tag">
                            { i18next.t('auto_complete_bar.creator') }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              },
            },
          },
          {
            sourceId: 'posts',
            getItems: () => {
              const results = getAlgoliaResults({
                searchClient: this.searchClient,
                queries: [
                  {
                    indexName: 'Post',
                    query,
                    params: {
                      hitsPerPage: 4,
                    },
                  },
                ],
              });
              return results
            },
            onSelect: (event) => {
              event.setQuery(event.item.title);
              window.location.href = `/posts?kw=${event.item.title}`
            },
            templates: {
              item: ({ item, components }) => {
                return (
                  <div className="aa-ItemWrapper">
                    <div className="aa-ItemContent">
                      <div className="aa-ItemContentBody">
                        <div className="aa-ItemContentTitle">
                          <components.Snippet hit={item} attribute="title"/>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              },
              footer: createFooterTemplate,
              noResults() {
                // create .aa-SourceNoResults div
                return '';
              },
            },
          },
        ];
      },
      onSubmit({ state }) {
        window.location.href = `/posts?kw=${state.query}`
      },
      renderer: { createElement: createElement, Fragment, render },
    })
  }
}

export default AutocompleteBar
