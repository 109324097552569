<template>
  <div id="post-item-collection" class="flex-1">
    <div v-if="items && items.length > 0" v-for="post in items" :key="post.id">
      <post-item :post="post"></post-item>
    </div>
    <div v-else-if="items_not_found" class="text-center post-item-not-found">
      <div class="icon"></div>
      <div class="title">
        {{ $t('post_index_collection.no_matching_post') }}
      </div>
      <div>
        {{ $t('post_index_collection.check_similar_results') }}
        <span v-if="is_logged_in">
          {{ $t('post_index_collection.or') }}
          <a
            href="#"
            class="btn btn-primary ml-6 inline-block text-14px py-1 bg-primary rounded-full"
            @click.prevent="toggleModal"
          >
            {{ $t('post_index_collection.request_model') }}
          </a>
        </span>
      </div>
      <alert-modal
        :alertState="alertState"
        :alertType="alertType"
        :alertMsg="alertMsg"
        @close-alert="closeAlert"
      ></alert-modal>
      <request-modal
        :modalState="modalState"
        @close="toggleModal"
        @alert="showAlert"
        @hide-alert="hideAlert"
      ></request-modal>
      <div class="popular-tags">
        <template
          v-if="popular_tags.length > 0"
          v-for="(tag, index) in popular_tags"
          :key="index"
        >
          <div v-if="tag.type == 'keyword'" class="popular-tag keyword-tag">
            <a
              :href="'/posts?kw=' + tag.kw"
              v-if="tag.index > 5"
              class="text-20px"
            >
              {{ tag.kw }}
            </a>
            <a
              :href="'/posts?kw=' + tag.kw"
              v-else-if="tag.index > 2"
              class="text-16px"
            >
              {{ tag.kw }}
            </a>
            <a :href="'/posts?kw=' + tag.kw" v-else class="text-14px">
              {{ tag.kw }}
            </a>
          </div>
          <div v-else class="popular-tag creator-tag">
            <a
              :href="'/posts?kw=' + tag.kw"
              v-if="tag.index > 4"
              class="text-20px"
            >
              {{ tag.kw }}
            </a>
            <a
              :href="'/posts?kw=' + tag.kw"
              v-else-if="tag.index > 2"
              class="text-16px"
            >
              {{ tag.kw }}
            </a>
            <a :href="'/posts?kw=' + tag.kw" v-else class="text-14px">
              {{ tag.kw }}
            </a>
          </div>
        </template>
      </div>
    </div>
    <div v-else-if="loading" class="text-center post-item-loading">
      {{ $t('post_index_collection.loading') }}
    </div>
  </div>
</template>

<script>
import 'toolcool-range-slider';
import { setWithExpiry, getWithExpiry } from "../utils/localCache";
const searchPath = "/api/v1/posts";
const cacheKey = "post_cols_cache_key";

export default {
  data() {
    return {
      perPage: 24,
      currPage: 0,

      autoload: true,

      // post list
      loading: false,
      items_not_found: false,
      items: [],

      popular_tags: [],
      is_logged_in: false,
      modalState: false,
      alertState: false,
      alertType: "",
      alertMsg: "",
    };
  },
  mounted() {
    this.setupQueryString();
    this.setupPriceRangeInput();

    // 如果有 cachedata，且 params 一樣的話就使用 cache 的內容
    // 反之就 fetch 新的資料
    const cacheData = getWithExpiry(cacheKey);
    if (cacheData && cacheData.params == this.currentQueryString) {
      this.currPage = cacheData.page;
      this.items = cacheData.data;
    } else {
      this.fetchItemData(this.currPage, this.perPage*2);
      this.currPage += 1
    }

    const SortingBtns = document.querySelectorAll(".post-sorting");
    SortingBtns.forEach((button) => {
      button.addEventListener("click", () => {
        let paramsObject = this.current_params;
        paramsObject["o"] = button.dataset.postSorting;

        delete paramsObject["page"];

        let newParams = new URLSearchParams(paramsObject);
        window.location.href = "/posts?" + newParams;
      });
    });

    const CategoryBtns = document.querySelectorAll(".post-category");
    CategoryBtns.forEach((button) => {
      button.addEventListener("click", () => {
        let clicked_id = button.dataset.postCategory;

        let paramsObject = this.current_params;
        let cate_ids = [];

        if (button.dataset.cateType == "cate_lv1") {
          paramsObject["c"] = clicked_id;
        } else {
          // category in params
          if (paramsObject["c"]) {
            cate_ids = paramsObject["c"].split(",");
          }

          // toggle category id
          if (cate_ids.indexOf(clicked_id) == -1) {
            cate_ids.push(clicked_id);
          } else {
            cate_ids = cate_ids.filter((value) => value !== clicked_id);
          }

          // sibling category
          let sibling_ids = [];
          button.parentNode
            .querySelectorAll("label.post-category")
            .forEach((btn) => {
              sibling_ids.push(btn.dataset.postCategory);
            });

          // 取交集
          cate_ids = new Set(cate_ids);
          sibling_ids = new Set(sibling_ids);
          let selected_ids = [...cate_ids].filter((value) =>
            sibling_ids.has(value)
          );

          if (selected_ids.length == 0) {
            delete paramsObject["c"];
          } else {
            paramsObject["c"] = selected_ids.join(",");
          }
        }

        delete paramsObject["page"];

        let newParams = new URLSearchParams(paramsObject);
        window.location.href = "/posts?" + newParams;
      });
    });

    const PriceBtns = document.querySelectorAll(".price-filter");
    PriceBtns.forEach((button) => {
      button.addEventListener("click", () => {
        let paramsObject = this.current_params;
        let priceRange = button.dataset.priceFilter.match(/\d+/g).map(Number)
        paramsObject["price_min"] = priceRange[0];
        paramsObject["price_max"] = priceRange[1];

        delete paramsObject["page"];
        let newParams = new URLSearchParams(paramsObject);
         window.location.href = "/posts?" + newParams;
      });
    });

    // remove category params
    const categoryFilterCleaner = document.getElementById(
      "category-filter-cleaner"
    );
    if (categoryFilterCleaner) {
      categoryFilterCleaner.addEventListener("click", () => {
        let paramsObject = this.current_params;

        delete paramsObject["c"];

        let newParams = new URLSearchParams(paramsObject);
        window.location.href = "/posts?" + newParams;
      });
    }

    window.addEventListener("scroll", () => {
      if (this.autoload == false) {
        return;
      }

      const scrollableHeight =
        document.body.scrollHeight - window.innerHeight - 600;
      const scrolledDistance = window.scrollY;

      if (scrolledDistance >= scrollableHeight) {
        if (this.loading == false) {
          this.fetchItemData();
        }
      }
    });
  },
  methods: {
    toggleModal() {
      this.modalState = !this.modalState;
    },
    showAlert(data) {
      this.alertState = data.alertState;
      this.alertType = data.alertType;
      this.alertMsg = data.alertMsg;
    },
    hideAlert() {
      setTimeout(() => {
        this.alertState = false;
        this.alertType = "";
      }, 3000);
    },
    closeAlert() {
      this.alertState = false;
      this.alertType = "";
    },
    async fetchItemData(currPage, perPage) {
      try {
        this.loading = true;
        this.items_not_found = false;

        let curr_params = { ...this.current_params };
        curr_params["per_page"] = perPage || this.perPage;

        // 資料要往後拉，所以 +1
        curr_params["page"] = (currPage || this.currPage) + 1;

        const response = await fetch(
          searchPath + "?" + new URLSearchParams(curr_params)
        );
        if (response.ok) {
          this.loading = false;

          const data = await response.json();
          const itemsData = data["data"];

          this.is_logged_in = data.is_authenticated;
          // 如果 params page > resp total_page
          // 等於拉到底了，就不用 autoload 了
          if (curr_params["page"] > data["pagination"].total_pages) {
            this.autoload = false;
          } else if (itemsData.length > 0) {
            // 如果有資料就要塞到 state 裏面，並更新頁碼
            this.items.push(...itemsData);
            this.currPage++;

            const cacheData = {
              params: this.currentQueryString,
              data: this.items,
              page: curr_params["page"],
            };

            setWithExpiry(cacheKey, cacheData, 1000);
          }

          // 如果沒有任何 items 就顯示推薦 tags
          if (this.items.length == 0) {
            this.items_not_found = true;

            const contentTitle = document.getElementById("post_index_title");
            contentTitle.classList.add("hidden");

            this.fetchPopularTagsData();
          }
        }
      } catch (error) {
        this.items = [];
        this.autoload = false;
      }
    },
    async fetchPopularTagsData() {
      try {
        const response = await fetch("/api/v1/posts/recommand_keywords");
        if (response.ok) {
          const data = await response.json();
          const keywordsData = data["data"];

          if (keywordsData.length != 0) {
            this.popular_tags = keywordsData;
          }
        }
      } catch (error) {}
    },
    setupQueryString() {
      const currParams = new URLSearchParams(window.location.search);
      const paramsObject = Object.fromEntries(currParams);

      this.currentQueryString =
        window.location.search.substring(1) || "no_params";
      this.current_params = paramsObject;

      if (paramsObject["page"]) {
        this.currPage = parseInt(paramsObject["page"]);
      }
    },
    setupPriceRangeInput() {
      let paramsObject = this.current_params;
      const minInput = document.querySelector(".price_min");
      const maxInput = document.querySelector(".price_max");
      minInput.value =  paramsObject["price_min"] || 0
      maxInput.value = paramsObject["price_max"] || 100
      minInput.addEventListener('blur',()=> {
        paramsObject['price_min'] = minInput.value
        paramsObject['price_max'] = maxInput.value
        let newParams = new URLSearchParams(paramsObject);
        window.location.href = "/posts?" + newParams;
      })
      maxInput.addEventListener('blur',()=> {
        paramsObject['price_min'] = minInput.value
        paramsObject['price_max'] = maxInput.value
        let newParams = new URLSearchParams(paramsObject);
        window.location.href = "/posts?" + newParams;
      })
    }
  },
};
</script>
