<template>
  <div class="modal-container">
    <div class="modal-black-cover"></div>
    <div class="modal-content-layout">
      <div class="modal-content-container p-6 pt-10 justify-center">
        <h2
          class="text-20px md:text-24px leading-tight font-bold text-center md:min-w-[400px]"
        >
          {{ $t('report_model.report_comment') }}
        </h2>
        <hr class="mx-auto mt-2 mb-4 md:mb-6 border-t-0 w-16 h-1 bg-primary" />

        <div class="report-container flex flex-col">
          <div class="report-options flex flex-col mb-4">
            <template v-for="opt in options">
              <label
                :for="opt.key"
                @click.prevent="optionChange(opt.key, opt.reason)"
                class="flex items-center py-2 text-lg"
              >
                <input
                  :id="opt.key"
                  class="custom-input"
                  type="radio"
                  name="category"
                  :value="opt.key"
                />
                <span class="custom__check mr-2"></span>
                {{ opt.reason }}
              </label>
            </template>
          </div>

          <div class="grid grid-cols-2 space-x-2">
            <a class="btn btn-secondary" @click.prevent="closeModal">
              {{ $t('report_model.cancel') }}
            </a>
            <button
              class="btn btn-primary"
              @click.prevent="submitReportModel"
              :disabled="!reportReason"
            >
              {{ $t('report_model.report') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { state, reportState } from "../stores/postState";

export default defineComponent({
  data() {
    return {
      options: [
        {
          key: 'spam',
          reason: this.$t('report_model.spam'),
        },
        {
          key: 'misinformation',
          reason: this.$t('report_model.misinformation'),
        },
        {
          key: 'porn',
          reason: this.$t('report_model.porn'),
        },
        {
          key: 'harassment',
          reason: this.$t('report_model.harassment'),
        },
        {
          key: 'hate',
          reason: this.$t('report_model.hate'),
        },
        {
          key: 'terrorism',
          reason: this.$t('report_model.terrorism'),
        }
      ],
      state,
      reportState
    }
  },
  props: ['reportReason'],
  methods: {
    closeModal() {
      this.reportState.openModal = false
      this.reportState.targetId = null
      this.reportState.targetType = null
      this.reportState.reportKey = null
      this.reportState.reportReason = null
    },
    optionChange(key, reason){
      this.reportState.reportKey = key
      this.reportState.reportReason = reason
    },
    submitReportModel() {
      if (!this.reportState.reportKey || !this.reportState.reportReason) {
        alert(this.$t('report_model.report_warning'))
        return
      }

      let requestUrl
      if (this.reportState.targetType == 'comment') {
        requestUrl = `/comments/${this.reportState.targetId}/report`
      } else {
        requestUrl = `/reviews/${this.reportState.targetId}/report`
      }

      const formData = new FormData()
      formData.append('category', this.reportState.reportKey)
      formData.append('content[text]', this.reportState.reportReason)

      fetch(requestUrl, {
        method: 'post',
        headers: {
          "X-CSRF-Token": this.state.CSRFtoken
        },
        body: formData
      })
      .then((res) => {
        if (res.ok) {
          alert(this.$t('report_model.success'))
        } else {
          alert(this.$t('report_model.duplicate'))
        }
      })
      .then((data) => {
        this.reportState.openModal = false
        this.reportState.targetId = null
        this.reportState.targetType = null
        this.reportState.reportKey = null
        this.reportState.reportReason = null
      })
    }
  },
});
</script>
../stores/postState