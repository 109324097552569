<template v-if="comment">
  <div :id = "`comment-item-${comment.id}`" 
       class='comment-item flex w-full my-4'>
    <img v-lazy="comment.profile.avatar_url" :alt="comment.profile.display_name"
         class='rounded-full mt-2'
         :class="comment.parent_id ? 'w-6 h-6' : 'w-8 h-8'">
    <template v-if="comment">
      <div class='comment-content ml-4 flex-1 w-full'>
        <div :id="'post_comment_'" class="grid grid-1fr-auto">
          <div class='w-full'>
            <div class="grid grid-auto-1fr">
              <div class="grid grid-1fr-auto-auto gap-x-2 mr-2 items-center">
                <a :href="comment.profile.public_url" class='truncate'>
                  <span class='text-16px font-medium truncate'>
                    {{ comment.profile.display_name }}
                  </span>
                </a>
                <span v-if='comment.profile.id == state.post_profile?.id' class='rounded-full px-2 py-0 bg-primary text-12px'>
                  {{ $t('comment_form.creator') }}
                </span>
                <span v-else-if="comment.profile?.is_admin_user" class='rounded-full px-2 py-0 bg-primary text-12px'>
                  {{ $t('comment_form.admin') }}
                </span>
                <span v-if='comment.parent_id' class='created_at text-12px text-grey-5'>
                  {{ comment.created_at }}
                </span>
              </div>
            </div>
            <div v-if='!comment.parent_id' class='created_at text-12px text-grey-5'>
              {{ comment.created_at }}
            </div>
            <div class='content text-14px my-2'>
              {{ commentContent.state == 'original' ? commentContent.original : commentContent.translated }}
            </div>
            <button v-if="commentContent.state == 'original'" class="mb-4 text-grey-5 text-14px underline" @click="handleTranslate">
              {{ $t('comment_form.translate_btn') }} 
            </button>
            <button v-else class="mb-4 text-grey-5 text-14px underline" @click="handleTranslate">
              {{ $t('comment_form.original_btn') }} 
            </button>
          </div>
  
          <div v-if='state.curr_profile' class='comment-more relative'>
            <input :id="'comment-' + comment.id + '-more'" type='radio' name='comment-more' class='hidden'>
            <label :for="'comment-' + comment.id + '-more'" class='cursor-pointer'>
              <span class='icon more-icon'></span>
            </label>
            <div class='more-content absolute right-0'>
              <button v-if='state.curr_profile && state.curr_profile.id == comment.profile.id' @click.prevent='deleteComment(comment)' class='flex items-center' data-confirm='Are you sure?'>
                <span class='icon delete-icon mr-2'></span>
                <span class='min-w-[32px]'>{{ $t('comment_form.delete') }}</span>
              </button>
              <button v-else-if='state.curr_profile' class='flex items-center' @click.prevent='reportComment(comment)'>
                <span class='icon report-icon mr-2'></span>
                <span class='min-w-[32px]'>{{ $t('comment_form.report') }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class='post_likes flex items-center'>
          <span :id="'comment-like-' + comment.id + '-btn'"
                ref="likeBtn"
                class='icon like w-5 h-5'
                :class="comment.liked ? 'selected' : ''"
                @click='likeBtnClick(comment)'>
          </span>
          <span ref="likeCount" class='text-12px text-grey-5 ml-2 mr-4'>
            {{ comment.like_count || '' }}
          </span>
            <span ref="unlikeBtn"
                  class='icon unlike w-5 h-5'
                  :class="comment.unliked ? 'selected' : ''"
                  @click='unlikeBtnClick(comment)'>
            </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { state, reportState, commentState } from "../stores/postState";
import i18next from "i18next";

export default defineComponent({
  props: ['comment'],
  emits: ['review-comments-updated'],
  data() {
    return {
      reply_uploading: false,
      replyContent: '',
      // 用於顯示翻譯後的內容
      commentContent: {
        original: '',
        translated: '',
        state: 'original'
      },

      state,
      commentState,
      reportState,
    };
  },
  mounted() {
    this.commentContent = {
      original: this.comment.content,
      translated: '',
      state: 'original'
    }
  },
  methods: {
    likeBtnClick(comment) {
      this.likeComment(comment)
    },
    unlikeBtnClick(comment) {
      this.unlikeComment(comment)
    },
    deleteComment(comment) {
      this.deleteComment(comment);
    },
    reportComment(comment) {
      document.getElementById('comment-' + comment.id + '-more').checked = false

      this.reportState.openModal = true
      this.reportState.targetId = comment.id
      this.reportState.targetType = 'comment'
      this.reportState.reportKey = null
      this.reportState.reportReason = null
    },
    async likeComment(comment) {

      if (this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }
      console.log(this.$refs.likeBtn)
      try {
        let likeCount
        const likeIcon = this.$refs.likeBtn

        if (likeIcon.classList.contains('selected')) {
          const requestUrl = '/api/v1/comments/' + comment.id + '/likes'
          const response = await fetch(requestUrl, {method: 'delete'});

          if (response.ok) {
            const data = await response.json();
            likeIcon.classList.remove('selected')
            likeCount = data['like_count']

            if (likeCount == 0) {
              likeCount = ''
            }
          }
        } else {
          const requestUrl = '/api/v1/comments/' + comment.id + '/likes'
          const response = await fetch(requestUrl, {method: 'post'});

          if (response.ok) {
            const data = await response.json();
            likeCount = data['like_count']

            const unlikeIcon = this.$refs.unlikeBtn
            unlikeIcon.classList.remove('selected')
            likeIcon.classList.add('selected')
          }
        }

        likeIcon.nextSibling.innerHTML = likeCount
      } catch (error) {
        console.log(error)
      }
    },
    async unlikeComment(comment) {
      if (this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }

      try {
        const unlikeIcon = this.$refs.unlikeBtn

        if (unlikeIcon.classList.contains('selected')) {
          const requestUrl = '/api/v1/comments/' + comment.id + '/unlikes'
          const response = await fetch(requestUrl, {method: 'delete'});

          if (response.ok) {
            const data = await response.json();
            unlikeIcon.classList.remove('selected')
          }
        } else {
          const requestUrl = '/api/v1/comments/' + comment.id + '/unlikes'
          const response = await fetch(requestUrl, {method: 'post'});

          if (response.ok) {
            const likeIcon = this.$refs.likeBtn
            const likeCountRef = this.$refs.likeCount

            if (likeIcon.classList.contains('selected')) {
              likeIcon.classList.remove('selected')

              let likeCount = parseInt(likeCountRef.innerHTML)
              likeCount -= 1

              if (likeCount == 0) {
                likeCount = ''
              }

              likeCountRef.innerHTML = likeCount
            }

            unlikeIcon.classList.add('selected')
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async deleteComment(comment) {
      if (this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }

      if (this.state.curr_profile.id != comment.profile.id){
        return
      }

      try {
        const api_path = '/api/v1/comments/' + comment.id
        const response = await fetch(api_path, {method: 'delete'});

        if (response.ok) {
          this.reviewCommentsUpdated();
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleTranslate() {
      // content state
      if (this.commentContent.state == 'original') {
        this.commentContent.state = 'translated'
      } else {
        this.commentContent.state = 'original'
      }

      // 有翻譯過就不再翻譯
      if (this.commentContent.translated != '') return;
      
      const browserLocale = navigator.language;
      try {
        const api_path = '/api/v1/translates'
        const response = await fetch(api_path, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            target_locale: browserLocale,
            content: this.commentContent.original
          })
        });
        
        if (response.ok) {
          const data = await response.json();
          this.commentContent.translated = data['translate']
        }
      } catch (error) {
        console.error('translate', error)
      }
    },
    reviewCommentsUpdated() {
      this.$emit('review-comments-updated'); 
    }
  }
});
</script>
