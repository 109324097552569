import { createApp, reactive } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import ProfilePageReviewCollection from './layouts/profile_layouts/ProfilePageReviewCollection.vue';
import ProfileReviewItem from "./components/ProfileReviewItem.vue";

class ProfilePageReviewComponent {
  constructor({ container, scope }) {
    this.app = null;
    this.container = container;
    this.scope = scope;
  }

  init() {
    this.app = createApp(ProfilePageReviewCollection, {
      scope: this.scope
    });
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });

    this.app.component('profile-review-item', ProfileReviewItem);

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default ProfilePageReviewComponent;