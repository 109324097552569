import AutocompleteBar from '../controllers/vue/AutocompleteBar.js';
import HomePostComponent from '../controllers/vue/home_post_collection.js';
import PostIndexComponent from '../controllers/vue/post_index_collection.js';
import ProfileComponent from '../controllers/vue/profile_collection.js';
import PostImageSlideComponent from '../controllers/vue/post_image_slide_component.js';
import PostCommentsComponent from '../controllers/vue/post_comments_component.js';
import ProfilePageFollowComponent from '../controllers/vue/profile_page_follow_collection.js';
import ProfilePageModelComponent from '../controllers/vue/profile_page_model_collection.js';
import ProfilePageReviewComponent from '../controllers/vue/profile_page_review_collection.js';

window.AutocompleteBar = AutocompleteBar
window.HomePostComponent = HomePostComponent
window.PostIndexComponent = PostIndexComponent
window.ProfileComponent = ProfileComponent
window.PostImageSlideComponent = PostImageSlideComponent
window.PostCommentsComponent = PostCommentsComponent
window.ProfilePageFollowComponent = ProfilePageFollowComponent
window.ProfilePageModelComponent = ProfilePageModelComponent
window.ProfilePageReviewComponent = ProfilePageReviewComponent