<template>
  <div class="post-review-item">
    <div class="review relative flex justify-start flex-wrap w-full py-4  border-gray-30 border-b">
      <a :href="`/posts/${review.post.id}?#review_${review.id}`" class="flex justify-center items-center absolute top-4 right-5 btn-primary w-8 h-8 rounded-full" >
        <div class="w-5 h-5 object-contain icon link_to pointer"></div>
      </a>
      <div class="post-container flex smd:flex-wrap flex-row w-full sm:w-5/12 lg:w-1/3 px-2 mb-4 md:mb-0">
        <div class="post-image flex">
          <img v-lazy="review.post.cover_image_src" :alt="review.post.title" class="w-32 h-32 rounded-8px overflow-hidden" />
        </div>
        <div class="post-title px-4 break-words text-14px">
          {{ truncate(review.post.title, 50, '...') }}
        </div>
      </div> 
      <div class="review-container flex flex-col w-full sm:w-7/12 lg:w-2/3 px-2" >
        <div class='review-rating flex items-center my-2'>
          <span v-for="rating in 5" :key="rating" class="icon" :class="{'star': review.stars >= rating, 'star-empty': review.stars < rating}"></span>
        </div>
        <div class="content text-14px mt-1 mb-2 break-words sm:pr-16 lg:pr-32">
          {{ review.content }}
        </div>
        <template v-if="review.images.length > 0">
          <div class="images flex flex-wrap mb-2">
            <!-- max image: 5-->
            <img
              v-for="(image, index) in review.images"
              :key="index"
              :src="image.x1_url"
              class="w-[72px] h-[72px] mr-2 mb-2 rounded-lg"
              :alt="`Image ${index + 1}`"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from "vue";
  import { setupSharedLinks } from '../utils/postItemSharedLinks';
  import Currency from './Currency.vue';

  export default defineComponent({
    data() {
      return {

      };
    },
    props: ['review'],
    mounted() {

    },
    methods: {
      labelClick(e) {
        let btn = document.getElementById(e.target.dataset.for)

        if (btn.checked) {
          btn.checked = false
        } else {
          btn.checked = true
          setupSharedLinks(e.target)
        }
      },
      truncate(text, length, separator = '...') {
        if (!text) return '';
        if (text.length <= length) return text;
        return text.substring(0, length) + separator;
      },
      async savePost() {
        if(this.is_authenticated){
          fetch(`/api/v1/posts/${this.post.id}/save`, {
            method: 'POST',
          })
          .then(response => response.json())
          .then(data => {
            if (data.status === 'saved') {
              this.saved_post = true;
            } else {
              this.saved_post = false;
            }
            this.saved_count = data.count;
          })
          .catch(error => {
            console.error('Error saving post:', error);
          });
        } else {
          window.location.href = '/users/sign_in';
        }
      },
    },
    components: {
      Currency
    } 
  })
</script>

<style>
.max-w-32 {
  max-width: 128px;
}

@media (min-width: 1280px) {
  .max-w-32 {
    max-width: none;
  }
}

.min-w-32 {
  min-width: 128px;
}
</style>