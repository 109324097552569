<template>
  <div   :id = "`review-${review.id}`"
         class='comment-item flex w-full mb-4'>
    <img v-lazy="review.profile.avatar_url" :alt="review.profile.display_name" class='rounded-full w-8 h-8 mt-2'>

    <div class='flex w-full'>
      <div class='comment-content ml-4 flex-1'>
        <div class='grid grid-auto-1fr mr-2'>
          <a :href="review.profile.public_url" class='truncate'>
            <span class='text-16px font-medium truncate'>
              {{ review.profile.display_name }}
            </span>
          </a>
        </div>

        <div class='text-12px text-grey-5'>
          {{ review.created_at }}
        </div>

        <div class='review-rating flex items-center my-2'>
          <template v-for='rating in [1,2,3,4,5]' :key="rating">
            <span class='icon '
                  :class="review.stars >= rating ? 'star' : 'star-empty'">
            </span>
          </template>
        </div>

        <div class='content text-14px my-2'>
          {{ reviewContent.state == 'original' ? reviewContent.original : reviewContent.translated }}
        </div>

        <button v-if="reviewContent.state == 'original'" class="mb-4 text-grey-5 text-14px underline" @click="handleTranslate">
          {{ $t('comment_form.translate_btn') }} 
        </button>
        <button v-else class="mb-4 text-grey-5 text-14px underline" @click="handleTranslate">
          {{ $t('comment_form.original_btn') }} 
        </button>

        <div v-if='review.images.length > 0' class='flex flex-wrap gap-2 mt-2 mb-4'>
          <template v-for="image in review.images" :key="image.id">
            <div v-lazy:background-image="image.x1_url"
                 class='inline-block image rounded-8px cursor-pointer'
                 @click.prevent='openImageSlide(image)'>
            </div>
          </template>
        </div>

        <!-- like/unlike/reply Btn -->
        <div class='post_likes flex items-center'>
          <span :id="'review-like-' + review.id + '-btn'"
                ref="likeBtn"
                class='icon like w-5 h-5'
                :class="review.liked ? 'selected' : ''"
                @click='likeBtnClick(review)'>
          </span>
          <span ref="likeCount" class='text-12px text-grey-5 ml-2 mr-4'>
            {{ review.like_count || '' }}
          </span>
          <span ref="unlikeBtn"
                class='icon unlike w-5 h-5'
                :class="review.unliked ? 'selected' : ''"
                @click='unlikeBtnClick(review)'>
          </span>
          <span v-if='(state.curr_profile?.id == state.post_profile?.id || state.curr_profile?.is_admin_user) && !hasCommented'
                class='ml-4 text-12px cursor-pointer cancel-btn'
                @click='replyBtnClick(review)'>
            {{ $t('comment_form.reply') }}
          </span>
        </div>
        <!-- reply content display -->
        <div v-if="review.replies && review.replies.length > 0">
          <div class='review_replies_content -mb-4'>
            <post-review-comment
              v-for="reply in review.replies"
              :key="reply.id" 
              :comment="reply"
              @review-comments-updated="reviewCommentsUpdated" >
            </post-review-comment>
          </div>
        </div>

        <!-- reply form -->
        <div v-if='(state.curr_profile?.id == state.post_profile?.id || state.curr_profile?.is_admin_user) && reply_review_id == review.id && !hasCommented'
              ref="reviewReplyForm"
              id='review-reply-form' 
              class='mt-4'>
          <div class='flex mt-2'>
            <img v-lazy="state.curr_profile?.avatar_url" :alt="state.curr_profile?.display_name" class='rounded-full w-6 h-6'>
            <textarea class='mt-4 ml-4 w-full h-12 p-2 text-xs text-gray-10 focus:outline-none w-full default-input-klass'
              v-model='replyContent' @keyup='updateReplyContent' />
          </div>
          <div class='flex justify-end mt-2'>
            <button class="px-2 btn-sm cancel-btn btn mr-2"
                    @click.prevent="cancelReplyReview()">
              {{ $t('comment_form.cancel') }}
            </button>
            <button v-if='reply_uploading' 
                    id='reply-review'
                    class="px-2 btn-sm rounded-full btn btn-secondary"
                    :disabled="true">
              {{ $t('comment_form.uploading') }}
            </button>
            <button v-else
                    id='reply-review'
                    class="px-2 btn-sm rounded-full btn btn-secondary"
                    @click.prevent="submitReplyReview"
                    :disabled="!replyContent">
              {{ $t('comment_form.reply') }}
            </button>
          </div>
        </div>
      </div>
      <div v-if='state.curr_profile' class='review-more relative'>
        <input :id="'review-' + review.id + '-more'" type='radio' name='review-more' class='hidden' :checked="reviewState.editMode">
        <label :for="'review-' + review.id + '-more'" class='cursor-pointer'>
          <span class='icon more-icon'></span>
        </label>
        <div class='more-content absolute right-0'>
          <button v-if='reviewState.curr_profile_review && reviewState.curr_profile_review.id == review.id && !reviewState.editMode'
                  @click.prevent='editReview'
                  class='flex items-center'>
            <span class='icon edit-icon mr-2'></span>
            <span class='min-w-[32px]'>{{ $t('post_review.edit') }}</span>
          </button>
          <button v-else-if='state.curr_profile' class='flex items-center' @click.prevent='reportReview(review)'>
            <span class='icon report-icon mr-2'></span>
            <span class='min-w-[32px]'>{{ $t('post_review.report') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PostReviewComment from './PostReviewComment.vue';
import { state, slideState, reportState, reviewState } from "../stores/postState";
import i18next from "i18next";

export default defineComponent({
  props: ['review'],
  emits: ['review-comments-updated'],  
  data() {
    return {
      reply_uploading: false,
      replyContent: '',
      reply_review_id: '',
      // 用於顯示翻譯後的內容
      reviewContent: {
        original: '',
        translated: '',
        state: 'original'
      },

      state,
      slideState,
      reportState,
      reviewState
    };
  },
  mounted() {
    this.reviewContent = {
      original: this.review.content,
      translated: '',
      state: 'original'
    }
  },
  methods: {
    editReview() {
      this.reviewState.editMode = true
    },
    openImageSlide(image) {
      this.slideState.fetchPath = '/review_images'
      this.slideState.openSlide = true
      this.slideState.images = []
      this.slideState.startImageId = image.id
    },
    reportReview(review) {
      document.getElementById('review-' + review.id + '-more').checked = false

      this.reportState.openModal = true
      this.reportState.targetId = review.id
      this.reportState.targetType = 'post_review'
      this.reportState.reportKey = null
      this.reportState.reportReason = null
    },
    async handleTranslate() {
      // content state
      if (this.reviewContent.state == 'original') {
        this.reviewContent.state = 'translated'
      } else {
        this.reviewContent.state = 'original'
      }

      // 有翻譯過就不再翻譯
      if (this.reviewContent.translated != '') return;
      
      try {
        const api_path = '/api/v1/translates'
        const response = await fetch(api_path, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            target_locale: i18next.language,
            content: this.reviewContent.original
          })
        });
        
        if (response.ok) {
          const data = await response.json();
          this.reviewContent.translated = data['translate']
        }
      } catch (error) {
        console.error('translate', error)
      }
    },
    likeBtnClick(review) {
      this.likeReview(review)
    },
    unlikeBtnClick(review) {
      this.unlikeReview(review)
    },
    async likeReview(review) {
      if (this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }
      console.log(this.$refs.likeBtn)
      try {
        let likeCount
        const likeIcon = this.$refs.likeBtn

        if (likeIcon.classList.contains('selected')) {
          const requestUrl = '/api/v1/post_reviews/' + review.id + '/likes'
          const response = await fetch(requestUrl, {method: 'delete'});

          if (response.ok) {
            const data = await response.json();
            likeIcon.classList.remove('selected')
            likeCount = data['like_count']

            if (likeCount == 0) {
              likeCount = ''
            }
          }
        } else {
          const requestUrl = '/api/v1/post_reviews/' + review.id + '/likes'
          console.log(requestUrl);
          try {
            const response = await fetch(requestUrl, {method: 'post'});
            
            if (response.ok) {
              const data = await response.json();
              likeCount = data['like_count']
              console.log('Response data:', data);
  
              const unlikeIcon = this.$refs.unlikeBtn
              unlikeIcon.classList.remove('selected')
              likeIcon.classList.add('selected')
            } else {
              console.error('Failed to like the review:', response.status, response.statusText);
            }
            
          } catch (error) {
            console.error('Error during fetch:', error);

          }
        }

        likeIcon.nextSibling.innerHTML = likeCount
      } catch (error) {
        console.log(error)
      }
    },
    async unlikeReview(review) {
      if (this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }

      try {
        const unlikeIcon = this.$refs.unlikeBtn

        if (unlikeIcon.classList.contains('selected')) {
          const requestUrl = '/api/v1/post_reviews/' + review.id + '/unlikes'
          const response = await fetch(requestUrl, {method: 'delete'});

          if (response.ok) {
            const data = await response.json();
            unlikeIcon.classList.remove('selected')
          }
        } else {
          const requestUrl = '/api/v1/post_reviews/' + review.id + '/unlikes'
          const response = await fetch(requestUrl, {method: 'post'});

          if (response.ok) {
            const likeIcon = this.$refs.likeBtn
            const likeCountRef = this.$refs.likeCount

            if (likeIcon.classList.contains('selected')) {
              likeIcon.classList.remove('selected')

              let likeCount = parseInt(likeCountRef.innerHTML)
              likeCount -= 1

              if (likeCount == 0) {
                likeCount = ''
              }

              likeCountRef.innerHTML = likeCount
            }

            unlikeIcon.classList.add('selected')
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    replyBtnClick(review) {
      if (this.reply_review_id != review.id) {
      this.replyContent = ''
      this.reply_review_id = review.id
      } else {
        this.reply_review_id = ''
      }
    },
    updateReplyContent(e) {
    this.replyContent = e.target.value
    },
    cancelReplyReview() {
    this.replyContent = ''
    this.reply_review_id = ''
    },
    async submitReplyReview(e) {
      this.reply_uploading = true
      const requestUrl = `/posts/${this.state.post_id}/reviews/${this.reply_review_id}/comments`;

      const formData = new FormData()
      
      formData.append('comment[parent_id]', this.reply_review_id)
      formData.append('comment[content]', this.replyContent)

      fetch(requestUrl, {
        method: 'post',
        headers: {
          "X-CSRF-Token": this.state.CSRFtoken
        },
        body: formData,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then(errData =>{
              throw new Error(errData.message || errData.error || 'Error in submission' );
            })
          }
        })
        .then((data) => {
          console.log('post review!!!', data)
          this.reply_uploading = false
          this.replyContent = '';

          this.reviewCommentsUpdated();
        })
    },
    reviewCommentsUpdated() {
      this.$emit('review-comments-updated'); 
    }
  },
  computed: {
    hasCommented() {
      return this.review.replies.some(reply => reply.profile && reply.profile?.id === this.state.curr_profile?.id);
    }
  }
});
</script>
../stores/postState