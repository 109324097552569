<template>
  <div id='post-images-slide' class="swiper-container rounded-xl overflow-hidden">
    <div class="swiper-wrapper">
      <template v-for="image in state.images" :key="image.id">
        <div v-if="image.type == 'attachment'" class="swiper-slide aspect-square block rounded-xl overflow-hidden max-w-[671px] max-h-[671px]">
          <img
            class='swiper-lazy w-full h-full'
            :src='image.x3_url'
            :data-src='image.x3_url'
          />
        </div>
        <div v-else class="swiper-slide aspect-square block rounded-xl overflow-hidden max-w-[671px] max-h-[671px]">
          <iframe width="100%" height="100%"
                :src="'https://www.youtube.com/embed/' + image.video"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </template>
    </div>
    <div class="swiper-button-prev">
      <div class='icon swiper-btn w-4 h-4 sm:w-6 sm:h-6 -rotate-90'></div>
    </div>

    <div class="swiper-button-next">
      <div class='icon swiper-btn w-4 h-4 sm:w-6 sm:h-6 rotate-90'></div>
    </div>

    <div class="hidden smd:flex swiper-pagination flex gap-x-2 py-1.5 px-2 items-center rounded-8px text-14px">
      <div class='icon swiper-pagy'></div>
      <span></span>
    </div>
  </div>

  <div id='post-images-slide-thumbs' class="swiper-container my-2 sm:my-4 overflow-hidden md:hidden">
    <div class="swiper-wrapper">
      <template v-for="image in state.images" :key="image.id">
        <div v-if="image.type == 'attachment'" class="swiper-slide block rounded-xl overflow-hidden">
          <img
            class='swiper-lazy w-full h-full'
            :src='image.x1_url'
            :data-src='image.x1_url'
          />
        </div>
        <div v-else class="swiper-slide aspect-square rounded-xl overflow-hidden flex items-center justify-center">
          <span>
            Youtube
          </span>
        </div>
      </template>
    </div>
  </div>

  <post-image-full-screen-slide v-if='state.openSlide' :slideState='state'></post-image-full-screen-slide>
</template>

<script>
  import { defineComponent } from "vue";
  import { state, slideState } from "../stores/postState";
  import Swiper, { Navigation, Pagination, Lazy, Controller } from 'swiper';
  
  export default defineComponent({
    data() {
      return {
        state,
        slideState
      };
    },
    mounted() {
      this.state.post_id = window.location.href.match(/\/posts\/(\d+)/)[1]

      this.getPostImages().then(() => {
        this.postImageSlider()
      })
    },
    methods: {
      async getPostImages(){
        try {
          if ( this.state.images.length > 0 ) {
            return
          }

          const api_path = '/api/v1/posts/' + this.state.post_id + '/images'
          const response = await fetch(api_path);

          if (response.ok) {
            const data = await response.json();
            const imagesData = data['data']
            const resizeList = data['resize_lists']

            if (imagesData.length > 0) {
              this.state.images = imagesData;
              this.state.aspectRatio = Object.values(resizeList)[0];
            }
          }
        } catch (error) {
          this.state.images = [];
        }
      },
      postImageSlider() {
        self = this
        let slideCount = this.state.images.length

        var swiper = new Swiper('#post-images-slide', {
          modules: [Navigation, Pagination, Lazy, Controller],
          lazy: {
            loadPrevNext: true,
          },
          loop: true,
          loopedSlides: 12,
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 24,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            780: {
              slidesPerView: 1088/680,
            }
          },
          on: {
            init: function(){
              const pagy = document.querySelector('#post-images-slide .swiper-pagination span');

              pagy.innerHTML = String(this.realIndex + 1).padStart(2, '0') + ' / ' + String(slideCount).padStart(2, '0');
            },
            slideChange: function(){
              const pagy = document.querySelector('#post-images-slide .swiper-pagination span');

              pagy.innerHTML = String(this.realIndex + 1).padStart(2, '0') + ' / ' + String(slideCount).padStart(2, '0');
            },
            click: function(){
              self.slideState.fetchPath = '/images'
              self.slideState.openSlide = true
              self.slideState.images = self.state.images.filter(number => number.type == 'attachment');
              self.slideState.startImageId = self.state.images[this.realIndex].id
              self.slideState.aspectRatio = self.state.aspectRatio
            }
          }
        });

        var swiperThumbs = new Swiper('#post-images-slide-thumbs', {
          modules: [Navigation, Pagination, Lazy, Controller],
          lazy: {
            loadPrevNext: true,
          },
          loop: true,
          loopedSlides: 12,
          slidesPerView: 4,
          centeredSlides: true,
          spaceBetween: 8,
          slideToClickedSlide: true,
          breakpoints: {
            560: {
              slidesPerView: 5,
              spaceBetween: 16
            }
          },
        });

        swiper.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiper
      }
    }
  })
</script>
../stores/postState