<template>
  <div id="review-form-profile" class="flex items-center mb-4">
    <img
      v-lazy="reviewState.curr_profile.avatar_url"
      :alt="reviewState.curr_profile.display_name"
      class="rounded-full w-8 h-8"
    />
    <span class="ml-4 text-16px font-medium">
      {{ reviewState.curr_profile.display_name }}
    </span>
  </div>
  <form method="put">
    <div class="-mt-2">Rating</div>
    <div class="review-rating mt-2 mb-6">
      <template v-for="rating in [1, 2, 3, 4, 5]" :key="rating">
        <label
          @click.prevent="ratingStarChange(rating)"
          @mouseenter="ratingEnter(rating)"
          @mouseleave="ratingLeave"
        >
          <template v-if="ratingStarHover >= rating">
            <span class="icon star-hover"></span>
          </template>
          <template v-else-if="ratingStar >= rating">
            <span class="icon star"></span>
          </template>
          <template v-else>
            <span class="icon star-empty"></span>
          </template>
        </label>
      </template>
    </div>

    <div class="flex flex-col w-full relative">
      <textarea
        class="w-full h-24 p-2 text-xs text-gray-10 focus:outline-none w-full default-input-klass"
        name="review_content"
        v-model="reviewContent"
        :placeholder="$t('review_form.placeholder')"
        maxlength="1000"
      />
      <div class="absolute bottom-2 right-2 text-xs text-gray-300">
        {{ reviewContentCount }}
      </div>
    </div>

    <div class="flex -mx-1 my-1">
      <div v-for="(file, index) in reviewImageFiles" class="relative">
        <div
          @click.prevent="deleteImg(index)"
          class="delete-icon absolute w-5 h-5 right-0 top-0 cursor-pointer"
        ></div>
        <div
          v-lazy:background-image="parseImgUrl(file)"
          class="image rounded-8px w-10 h-10 bg-cover m-1"
        ></div>
      </div>
    </div>

    <div class="flex justify-between">
      <label class="btn btn-sm btn-secondary rounded-full pl-1 pr-2">
        <div class="flex items-center">
          <span class="icon w-8 h-8 -my-2"></span>
          <span>{{ $t('review_form.add_btn') }}</span>
        </div>
        <input
          id="review_images"
          class="hidden"
          multiple="multiple"
          name="review[images_attributes][][file]"
          accept="image/jpeg,image/png,image/jpg"
          type="file"
          v-on:change="reviewImgUpload"
        />
      </label>
      <span>
        <button
          class="px-2 btn-sm btn mr-2 hover:text-orange-20"
          v-if="reviewContent"
          @click.prevent="cancelNewReview"
        >
          {{ $t('review_form.cancel') }}
        </button>
        <button
          v-if="uploading"
          id="submit-review"
          class="px-2 btn-sm rounded-full btn btn-secondary"
          :disabled="true"
        >
          {{ $t('review_form.uploading') }}
        </button>
        <button
          v-else
          id="submit-review"
          class="px-2 btn-sm rounded-full btn btn-secondary"
          @click.prevent="submitReview"
          :disabled="!reviewContent || ratingStar == 0"
        >
          {{ $t('review_form.comment') }}
        </button>
      </span>
    </div>
  </form>
</template>

<script>
import { defineComponent } from "vue";
import { state, reviewState } from "../stores/postState";

export default defineComponent({
  data() {
    return {
      uploading: false,
      ratingStarHover: 0,
      ratingStar: 0,
      reviewContent: '',
      reviewImageFiles: [],

      state,
      reviewState
    };
  },
  computed: {
    reviewContentCount() {
      return `${this.reviewContent.length} / 1000`;
    },
  },
  mounted() {
    this.ratingStar = (this.reviewState.curr_profile_review && this.reviewState.curr_profile_review.stars) || 0
    this.reviewContent = (this.reviewState.curr_profile_review && this.reviewState.curr_profile_review.content) || ''
    
    const curr_review = this.reviewState.curr_profile_review;
    if (curr_review) {
      if (curr_review.stars) {
        this.ratingStar = curr_review.stars;
      }

      if (curr_review.content) {
        this.reviewContent = curr_review.content;
      }

      if (curr_review.images.length > 0) {
        this.fetchReviewImages(curr_review.images);
      }
    }
  },
  methods: {
    fetchReviewImages(images) {
      images.forEach((img) => {
        fetch(img.url)
          .then((response) => response.blob())
          .then((blob) => {
            var filename = img.url.split("/").slice(-1)[0];
            var file = new File([blob], filename, { type: blob.type });

            this.reviewImageFiles.push(file);
          });
      });
    },
    reviewImgUpload(e) {
      e.target.files.forEach((file) => {
        this.reviewImageFiles.push(file);
      });
    },
    parseImgUrl(file) {
      return URL.createObjectURL(file);
    },
    deleteImg(index) {
      this.reviewImageFiles.splice(index, 1);
    },
    cancelNewReview() {
      this.ratingStar = 0;
      this.reviewContent = "";
      this.reviewImageFiles = [];

      this.reviewState.editMode = false;
    },
    ratingEnter(value) {
      this.ratingStarHover = value;
    },
    ratingLeave() {
      this.ratingStarHover = 0;
    },
    ratingStarChange(value) {
      this.ratingStar = value;
    },
    submitReview(e) {
      this.uploading = true;

      let requestUrl;
      let requestMethod;

      if (this.reviewState.editMode) {
        requestUrl = `/posts/${this.state.post_id}/reviews/${this.reviewState.curr_profile_review.id}`;
        requestMethod = "put";
      } else {
        requestUrl = `/posts/${this.state.post_id}/reviews`;
        requestMethod = "post";
      }

      const formData = new FormData();
      formData.append("review[content]", this.reviewContent);
      formData.append("review[stars]", this.ratingStar);
      this.reviewImageFiles.forEach(function (file) {
        formData.append("review[images_attributes][][file]", file);
      });

      fetch(requestUrl, {
        method: requestMethod,
        headers: {
          "X-CSRF-Token": this.state.CSRFtoken,
        },
        body: formData,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((errData) => {
              throw new Error(errData.message || "Error in submission");
            });
          }
        })
        .then((data) => {
          this.uploading = false;
          this.ratingStar = 0;
          this.reviewContent = "";
          this.reviewImageFiles = [];

          this.reviewState.editMode = false;
          this.fetchReviewItemData();
        })
        .catch((error) => {
          this.uploading = false;
          this.fetchReviewItemData();
        });
    },
    async fetchReviewItemData() {
      try {
        const api_path = '/api/v1/posts/' + this.state.post_id + '/reviews'
        const response = await fetch(api_path);
        if (response.ok) {
          const data = await response.json();
          const itemsData = data['data']

          this.reviewState.reviewable = data['reviewable']
          this.reviewState.curr_profile = data['current_profile']
          this.reviewState.curr_profile_review = data['current_profile_review']

          if (itemsData.length == 0) {
            this.reviewState.review_items_not_found = true
          } else {
            this.reviewState.selected_review_items = itemsData;
            this.reviewState.review_items = itemsData;
          }
        }
      } catch (error) {
        this.reviewState.review_items = [];
      }
    }
  },
});
</script>
