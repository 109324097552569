<template>
  <div class='w-account-container my-4'>
    <div class='flex items-center text-18px bg-grey-4 rounded-t-8px'>
      <div id='post-reviews-tab' :class="'nav cursor-pointer ' + displayTypeClass('review')" @click.prevent="switchDisplayType('review')">
        {{ $t('post_comments_collection.reviews_and_ratings') }}
        <span class="text-primary px-1">
          ({{reviewState.review_items.length}})
        </span>
      </div>
      <div id='post-comments-tab' :class="'nav cursor-pointer ' + displayTypeClass('comment')" @click.prevent="switchDisplayType('comment')">
        {{ $t('post_comments_collection.comments') }}
        <span class="text-primary px-1">
          ({{commentState.comment_items.length}})
        </span>
      </div>
    </div>
    <div class="p-4 bg-grey-7 rounded-b-8px">
      <template v-if="state.display_type == 'comment'">
        <comment-form></comment-form>
        <template v-if='commentState.comment_items.length > 0'>
          <hr class="border-grey-5 my-4">
        </template>
      </template>

      <template v-else-if='(reviewState.reviewable && !reviewState.curr_profile_review) || reviewState.editMode'>
        <review-form></review-form>
        <template v-if='reviewState.review_items.length > 0'>
          <hr class="border-grey-5 my-4">
        </template>
      </template>

      <template v-if="state.display_type == 'comment'">
        <div v-if='commentState.comment_items.length > 0' id='post-comments' class='-mb-4'>
          <template v-for="comment in commentState.comment_items" :key="comment.id">
            <post-comment
              :comment='comment'
              :reply_comment_id='commentState.reply_comment_id'>
            </post-comment>
          </template>
        </div>
      </template>

      <template v-else>
        <div v-if='reviewState.review_items.length > 0' id='post-comments' class='-mb-4'>
          <div class='review-header'>
            <select class="default-input-klass" data-uniform-select="true" v-on:change="selectStar">
              <option value='0'>
                {{ $t('post_comments_collection.all') }}
              </option>
              <template v-for='star in reviewState.reviewStars'>
                <option :value='star'>
                  {{ star }}
                  <template v-if='star > 1'>
                    {{ $t('post_comments_collection.stars') }}
                  </template>
                  <template v-else>
                    {{ $t('post_comments_collection.star') }}
                  </template>
                </option>
              </template>
            </select>

            <div v-if='!reviewState.curr_profile || !reviewState.reviewable' class='review-hint flex gap-2 items-center justify-center'>
              <span class='icon review-hint-icon'></span>
              <span class='flex-1'>
                {{ $t('post_comments_collection.leave_comment_hint') }}
              </span>
              <button @click.prevent="switchDisplayType('comment')" class="inline-flex items-center btn btn-primary py-1 px-3 rounded-full text-14px">
                {{ $t('post_comments_collection.comments') }}
              </button>
            </div>
          </div>

          <template 
            v-if='reviewState.selected_review_items.length > 0'
            v-for="review in reviewState.selected_review_items" :key="review.id">
            <post-review :review='review' @review-comments-updated="fetchReviewItemData"></post-review>
          </template>
        </div>
        <div v-if='!reviewState.reviewable && reviewState.review_items_not_found' class='review-hint text-center flex gap-2 items-center justify-center'>
          <span class='icon review-hint-icon'></span>
          <span>{{ $t('post_comments_collection.review_hint') }}</span>
          <span>{{ $t('post_comments_collection.leave_comment_hint') }}</span>
          <button @click.prevent="switchDisplayType('comment')" class="inline-flex items-center btn btn-primary py-1 px-3 rounded-full text-14px">
                {{ $t('post_comments_collection.comments') }}
          </button>
        </div>
      </template>
    </div>
  </div>

  <post-image-full-screen-slide v-if='slideState.openSlide' :slideState='slideState'></post-image-full-screen-slide>
  <report-model v-if='reportState.openModal' :reportReason='reportState.reportReason'></report-model>
</template>

<script>
  import { defineComponent } from "vue";
  import { state, commentState, reviewState, reportState, slideState } from "../stores/postState";
  
  export default defineComponent({
    data() {
      return {
        state,
        commentState,
        reviewState,
        slideState,
        reportState,
        scrolled: false,
      };
    },
    mounted() {
      // set post_id
      this.state.post_id = window.location.href.match(/\/posts\/(\d+)/)[1]
      // rspec 沒有 csrf
      if(document.querySelector('meta[name="csrf-token"]')) {
        this.state.CSRFtoken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }

      if(this.state.post_id) {
        // Fetch comment item data when the component is created
        this.fetchCommentItemData(this.state.post_id).then(() => {
          // 使用網址移動到指定留言
          const comment_id = window.location.href.match(/#comment_(\d+)/) && window.location.href.match(/#comment_(\d+)/)[1]
          this.commentState.target_comment_id = comment_id
        });

        // Fetch review item data when the component is created
        this.fetchReviewItemData().then(() => {
          let starArray = []

          this.reviewState.review_items.forEach(function(review) {
            starArray.push(review.stars)
          })

          let uniqueArray = [...new Set(starArray)];

          uniqueArray.sort((a, b) => a - b);

          this.reviewState.reviewStars = uniqueArray.reverse();
        }).catch(e => {
          console.error('Error during fetchReviewItemData:', e);
        });
      }
    },
    updated(){
      window.initUniformSelect()
      if (!this.scrolled) {
        const target = this.getCurrentURLPrefixAndId()
        if (!target || !target['id']) {
          this.scrolled = true
          return;
        }
        this.scrollToTarget(target);
      } 
    },
    methods: {
      displayTypeClass(type) {
        if (this.state.display_type == type){
          return 'selected'
        }
      },
      switchDisplayType(type) {
        this.state.display_type = type
        // 重置 review
        this.reviewState.selected_review_items = this.reviewState.review_items
      },
      selectStar(e){
        let selected_data = []
        if (e.target.value > 0) {
          this.reviewState.review_items.forEach(function(data){
            if (data.stars == e.target.value) {
              selected_data.push(data)
            }
          })
        } else {
          selected_data = this.reviewState.review_items
        }

        this.reviewState.selected_review_items = selected_data
      },
      async fetchCommentItemData(post_id) {
        try {
          const api_path = '/api/v1/posts/' + post_id + '/comments'
          const response = await fetch(api_path);
          if (response.ok) {
            const data = await response.json();
            const itemsData = data['data']

            this.state.post_profile = data['post_profile']
            this.state.curr_profile = data['current_profile']

            if (itemsData.length == 0) {
              this.commentState.comment_items_not_found = true
            } else {
              this.commentState.comment_items = itemsData;
            }
          }
        } catch (error) {
          console.error('Error:', error);
          this.commentState.comment_items = [];
        }
      },
      async fetchReviewItemData() {
        try {
          const api_path = '/api/v1/posts/' + this.state.post_id + '/reviews'
          const response = await fetch(api_path);
          if (response.ok) {
            const data = await response.json();
            const itemsData = data['data']
            this.reviewState.reviewable = data['reviewable']
            this.reviewState.curr_profile = data['current_profile']
            this.reviewState.curr_profile_review = data['current_profile_review']

            if (itemsData.length == 0) {
              this.reviewState.review_items_not_found = true
            } else {
              this.reviewState.selected_review_items = itemsData;
              this.reviewState.review_items = itemsData;
            }
          }
        } catch (error) {
          this.reviewState.review_items = [];
        }
      },
      scrollToTarget(target) {
        if(target['prefix'] === 'comment'){
          this.switchDisplayType('comment')
        }
        const targetElement = document.getElementById(`${target['prefix']}-${target['id']}`)
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          this.scrolled = true
        }
      },
      getCurrentURLPrefixAndId() {
        const url = window.location.href;
        const match = url.match(/#(comment|review|comment-item)_(\d+)$/);
        if (match) {
          return {
            prefix: match[1],
            id: match[2]
          };
        }
        return null;
      }
    }
  })
</script>
../stores/postState