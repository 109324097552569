// state.js
import { reactive } from 'vue';

export const state = reactive({
  display_type: 'review',
  post_id: null,
  CSRFtoken: null,
  curr_profile: null,
  post_profile: null,
  images: [],
  aspectRatio: [1, 1]
})

export const commentState = reactive({
  target_comment_id: null,
  reply_comment_id: null,
  comment_items_not_found: false,
  comment_items: []
});

export const reportState = reactive({
  openModal: false,
  targetId: null,
  targetType: null,
  reportKey: null,
  reportReason: null
});

export const slideState = reactive({
  fetchPath: '/comment_images',
  openSlide: false,
  images: [],
  startImageId: null,
  aspectRatio: [1, 1]
});

export const reviewState = reactive({
  curr_profile_review: null,
  editMode: false,
  reviewable: false,
  reviewStars: [5,4,3,2,1],
  review_items_not_found: false,
  review_items: [],
  selected_review_items: []
});

export const exchangeState = reactive({
  rates: null
});
